<template>
  <div class="pane">
    <ul class="list">
      <li v-for="setting of settings" :key="setting.title" class="options-group">
        <fieldset>
          <label>{{ setting.title }}</label>
          <div class="options">
            <ul>
              <li v-for="option of setting.options" :key="option" class="option" @click="selectOption(setting, option)">
                <div class="check-icon" :class="{ check: option === setting.value }">
                  <Icon size="sm" :name="option === setting.value ? 'checkbox-blank-circle' : 'checkbox-blank-circle-outline'" />
                </div>
                <span>{{ option }}</span>
              </li>
            </ul>
          </div>
        </fieldset>
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    const settings = [];
    const skip = ['formTitle', 'source', 'firstShow'];
    for (const prop of Object.getOwnPropertyNames(this.data).filter(p => !skip.includes(p))) {
      settings.push({
        name: prop,
        ...this.data[prop]
      });
    }

    return {
      settings
    };
  },
  mounted() {
    if (this.data.firstShow) {
      this.$store.dispatch(`${this.data.source}/settingChanged`, { setting: 'firstShow', value: false });
    }
  },
  methods: {
    selectOption(setting, value) {
      setting.value = value;
      this.$store.dispatch(`${this.data.source}/settingChanged`, { setting: setting.name, value });
    }
  }
};
</script>

<style lang="scss" scoped>
.pane {
  .list {
    margin-left: -5px;
  }

  .options-group {
    padding: 5px;
    justify-self: stretch;
    user-select: none;
    background-color: var(--theme-background);
    border-radius: 2px;

    &:not(:last-child) {
      margin-bottom: 3px;
    }

    label {
      font-size: 0.8rem;
      font-weight: 600;
      color: var(--theme-on-background-accent);
      opacity: 0.8;
    }

    .options {
      padding: 5px;
      padding-left: 0;

      .option {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;

        cursor: pointer;

        border-radius: 2px;
        font-size: 0.9rem;
        padding: 5px;
        font-weight: 450;
        color: var(--theme-on-background-accent);

        &:hover {
          background-color: var(--theme-secondary);
          color: white;
        }

        .check-icon {
          padding-left: 5px;

          &.check {
            color: var(--theme-primary);
          }
        }

        span {
          padding-left: 5px;
          align-self: center;
        }
      }
    }
  }
}
</style>
